import WPDarkMode from "./DarkMode";
import Store from "./Store";
import Images from "./Images";
import Videos from "./Videos";
import Accessibility from "./Accessibility";
import Color from "./Color";
import Base from "./Base";

class DarkModeApp extends Base {

	/**
	 * Events
	 */
	events() {
		// Sync storages.
		this.syncStorage();
		
		// Register event listeners.
		this.RegisterEvents();

		// Emit init
		// this.emit('init');

		return this;
	}

	/**
	 * Get user choice.
	 *
	 * @return {string}
	 */
	get hasUserChoice() {
		return null !== Store.get( 'choice' );
	}

	/**
	 * Determine if the user is premium
	 */
	get isPremium() {
		return [1, '1'].includes( wp_dark_mode_json.is_ultimate ||  wp_dark_mode_json.is_pro)  // is_pro key is Deprecated, will be removed in future.
	}

	/**
	 * Sync storages
	 */
	async syncStorage() {
		/**
		 * Device preference for dark mode
		 */
		Store.set( 'device', WPDarkMode.isDeviceDark ? 'dark' : 'light' );

		/**
		 * These are premium features
		 */
		if ( ! this.isPremium ) return;

		/**
		 * Timezone of device, used for Time based dark mode
		 */
		let timeZoneOffset = (new Date().getTimezoneOffset()/60);

		// Add + sign if offset is negative. Add - sign if offset is positive.
		if (timeZoneOffset > 0) {
			timeZoneOffset = '-' + timeZoneOffset;
		} else {
			timeZoneOffset = '+' + Math.abs(Number(timeZoneOffset));
		}

		Store.set( 'timezone', timeZoneOffset );

		/**
		 * Location of device, used for sunset based dark mode
		 * Ask, only if sunset mode is enabled.
		 */
		if ( wp_dark_mode_json.options.frontend_mode === 'sunset' ) {
			this.syncDeviceLocation()
		}
	}

	/**
	 * Update device location
	 */
	async syncDeviceLocation() {

		// Check if location was updated in last 24 hours.
		const locationUpdated = Store.get( 'location-updated' );

		// Bail if location was updated in last 24 hours.
		if (locationUpdated) {
			if (((new Date()) - (new Date().setTime( locationUpdated ))) < (24 * 60 * 60 * 1000)) {
				return;
			}
		}

		// Bail, if navigator is not available or geolocation is not supported.
		if ( ! navigator || ! navigator.geolocation ) {
			return;
		}

		navigator.geolocation.getCurrentPosition( (position) => {
			// Save location for dark mode.
			Store.set( 'location', position.coords.latitude + ',' + position.coords.longitude );

			// Save location update time.
			Store.set( 'location-updated', new Date().getTime() );
		});

	}

	/**
	 * Follow device preference for dark mode
	 */
	followDevicePreference() {
		// Bail, if user choice is set.
		if (Store.get( 'choice' ) !== null) {
			return;
		}

		if (WPDarkMode.isDeviceDark) {
			WPDarkMode.activate();
		} else {
			WPDarkMode.deactivate();
		}
	}

	/**
	 * Register event listeners
	 */
	RegisterEvents() {

		// Adjust presets, colors, exclude elements, etc.
		Color.init();

		Accessibility.init();

		// Track device settings.
		this.TrackDeviceSettings();

		if ( this.isPremium ) {
			// Adjust media contents. 
			Images.init();

			Videos.init();
		}
	}

	/**
	 * Listen for device dark mode change
	 */
	TrackDeviceSettings() {

		// Bail, if device mode change is not supported.
		if ( ! window || ! window.matchMedia ) {
			return;
		}

		const mediaQuery = matchMedia('(prefers-color-scheme: dark)');

		if( ! mediaQuery ) return false;

		const checkSystemMode = () => {

			// Bail, if dark mode is not following device mode.
			if ( wp_dark_mode_json.options.frontend_mode !== 'device' ) return;

			// React to device preference change.
			if ( mediaQuery.matches ) {
				WPDarkMode.activate();
			} else {
				WPDarkMode.deactivate();
			}
		}

		mediaQuery.addEventListener('change', function() {
			
			// Update user device preference.
			Store.set( 'device', mediaQuery.matches ? 'dark' : 'light' );

			// React to device preference change.
			
			// Bail, if user choice is set.
			if (Store.get( 'choice' ) !== null) {
				return;
			}

			checkSystemMode()
		});

		// Check system mode on load.
		if ( ! WPDarkMode.isActive && !this.hasUserChoice ) {
			checkSystemMode()
		}
	}

}

export default new DarkModeApp();